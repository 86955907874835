/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.tooltip {
  position: absolute;
  z-index: 9001;
  display: block;
  height: auto;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  animation: fadeIn 0.3s forwards;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 22rem;
  text-align: left;
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
  word-break: break-word;
  padding: 12px 17px;
  color: #eff2f9;
  background-color: #2a2f40;
}

.tooltip-inner .alert {
  margin-bottom: 5px;
  border-radius: 4px;
}

.tooltip-inner a {
  border-bottom-color: #bdc6ff;
  color: #bdc6ff;
}

.tooltip-inner hr {
  background-color: #5d6d75;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  border-width: 5px 5px 0;
  transform: translateX(-5px);
  border-top-color: #2a2f40;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  transform: translateY(-5px);
  border-width: 5px 5px 5px 0;
  border-right-color: #2a2f40;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  transform: translateY(-5px);
  border-width: 5px 0 5px 5px;
  border-left-color: #2a2f40;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  transform: translateX(-5px);
  border-width: 0 5px 5px;
  border-bottom-color: #2a2f40;
}

/* Workaround for react issue with onMouseLeave in disabled buttons: https://github.com/facebook/react/issues/4251 */
.tooltip button[disabled] {
  pointer-events: none;
}

.tooltip .issue-message-highlight-CODE {
  background-color: rgba(255, 255, 255, 0.18);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
